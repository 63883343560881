import styled from "@emotion/styled"
import { Button, Text, theme } from "@pallet-hq/hegel"
import config from "config"
import { Layout } from "@pallet/features/onboardingL2/Layout"
import { useGroupQuery } from "@pallet/features/onboardingL2/useGroupQuery"
import { FiCheck } from "react-icons/fi"
import { fetchAuthTokenAndBounce } from "utils/auth"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"

export const TalentDoneView = () => {
    const palletContext = usePalletContext()
    const router = usePalletRouter()
    const groupQuery = useGroupQuery()

    return (
        <Layout hideNav={true}>
            <TalentFormBox>
                <div>
                    <IconDiv>
                        <FiCheck
                            size={48}
                            stroke={theme.colors.green700}
                            strokeWidth={3}
                        />
                    </IconDiv>
                    <Text
                        variant="uiSmall600"
                        mt="xsmall"
                        mb="xlarge"
                        textAlign="center"
                    >
                        Application submitted!
                    </Text>

                    <Text variant="titleMedium" mb="medium" textAlign="center">
                        Thank you for applying to {groupQuery.data?.group?.name}
                    </Text>
                    <Text
                        variant="paragraphLarge"
                        mb="xlarge"
                        textAlign="center"
                    >
                        We’ll update your application status over email. In the
                        mean time, finish building your profile to improve your
                        chances of recruiters seeing your profile.
                    </Text>
                    <Button
                        size="large"
                        width="100%"
                        mb="small"
                        onClick={() =>
                            fetchAuthTokenAndBounce(
                                palletContext,
                                router,
                                `${config.CANONICAL_URL}/profile/setup?step=intro&from=${window.location.href}`
                            )
                        }
                    >
                        Finish my profile
                    </Button>
                    <Button
                        size="large"
                        variant="secondary"
                        width="100%"
                        onClick={() => router.push("/jobs")}
                    >
                        Browse the job board
                    </Button>
                </div>
            </TalentFormBox>
        </Layout>
    )
}

const IconDiv = styled.div`
    width: ${theme.space.xxlarge};
    height: ${theme.space.xxlarge};
    background: ${theme.colors.green000};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`
