import { gql, useQuery } from "@apollo/client"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { GetCandidateApplicationGroupData } from "__generated__/GetCandidateApplicationGroupData"

export const GET_CANDIDATE_APPLICATION_GROUP_DATA = gql`
    query GetCandidateApplicationGroupData($pallet: String!) {
        group(slug: $pallet) {
            id
            name
            description
            candidateOnboardingDescription
            enableLayoffSharing
            canViewDrops
            applylist {
                id
                curatorName
                logo {
                    source
                }
            }
        }
    }
`

export const useGroupQuery = () => {
    const { pallet } = usePalletContext()
    return useQuery<GetCandidateApplicationGroupData>(
        GET_CANDIDATE_APPLICATION_GROUP_DATA,
        {
            variables: { pallet },
            ssr: false,
        }
    )
}
