import constate from "constate"
import { usePalletRouter } from "utils/hooks/usePalletRouter"

export const [CandidateApplicationProvider, useCandidateApplication] = constate(
    () => {
        const router = usePalletRouter()
        const { application, referral } = router.query
        const isModalOpen = !!application || !!referral

        const showModal = () => {
            router.push(
                { query: { ...router.query, application: true } },
                undefined,
                { shallow: true }
            )
        }

        const hideModal = () => {
            delete router.query.application
            delete router.query.referral
            router.push({ query: router.query }, undefined, {
                shallow: true,
            })
        }

        return {
            isModalOpen,
            showModal,
            hideModal,
        }
    }
)
