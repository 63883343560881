import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Theme, theme } from "@pallet-hq/hegel"

type ProgressBarProps = {
    currentStep: number
    totalSteps: number
}

export const ProgressBar = ({ currentStep, totalSteps }: ProgressBarProps) => {
    const theme = useTheme() as Theme
    return (
        <StyledProgressBar
            percentage={(currentStep / (totalSteps - 1)) * 100}
            color={theme.colors.primary}
        />
    )
}

const StyledProgressBar = styled.div<{
    percentage: number
    color: Theme["colors"]["primary"]
}>`
    position: relative;
    width: 100%;
    max-width: 220px;
    height: 8px;
    background: ${theme.colors.gray200};
    border-radius: 87px;

    &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: ${({ percentage }) => percentage}%;
        height: 100%;
        background: ${({ color }) => color};
        transition: width 0.1s ease;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        border-top-right-radius: ${({ percentage }) =>
            percentage === 100 ? "inherit" : 0};
        border-bottom-right-radius: ${({ percentage }) =>
            percentage === 100 ? "inherit" : 0};
    }
`
