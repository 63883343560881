import styled from "@emotion/styled"
import { KeyboardButton, notify, Text, Box, theme } from "@pallet-hq/hegel"
import { FlexRow } from "components/atoms"
import UnstyledButton from "components/Buttons/UnstyledButton"
import { Layout } from "@pallet/features/onboardingL2/Layout"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUpdateUserMutation } from "@pallet/features/onboardingL2//useUpdateUserMutation"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { Form, Formik, FormikProps, FormikValues } from "formik"
import { useRef } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { isMacintosh } from "utils/userAgent"
import * as Yup from "yup"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"
import { TalentFormFieldsBox } from "@pallet/features/onboardingL1/TalentFormFieldsBox"

export const TalentNetworkConsentView = () => {
    const { handleNext } = useNavigationControls()
    const [updateUser] = useUpdateUserMutation()
    const userQuery = useUserQuery()
    const modifierKey = isMacintosh() ? "⌘" : "Ctrl"
    const formRef = useRef<FormikProps<FormikValues>>(null)

    useHotkeys(
        "ctrl+enter, cmd+enter",
        () => {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
        },
        {
            enableOnTags: ["INPUT"],
        }
    )

    if (!userQuery.data?.me?.id) return null

    return (
        <Layout>
            <Formik
                initialValues={{
                    isPublicToNetwork:
                        userQuery.data?.me?.isPublicToNetwork ?? undefined,
                }}
                innerRef={formRef}
                validationSchema={Yup.object().shape({
                    isPublicToNetwork: Yup.boolean().required("Required"),
                })}
                onSubmit={async values => {
                    const { data } = await updateUser({
                        variables: {
                            ...values,
                        },
                    })
                    if (data?.updateJobSeeker?.userError) {
                        notify.fail("An error occurred. Please try again.")
                    } else {
                        handleNext()
                    }
                }}
                validateOnMount
            >
                {({ isValid, isSubmitting, setFieldValue, values }) => {
                    return (
                        <Form>
                            <TalentFormBox>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gridGap="small"
                                >
                                    <Text variant="titleMedium">
                                        Would you also like your profile to be
                                        shared as part of the Pallet
                                        Collectives?
                                    </Text>
                                    <Text variant="paragraphLarge">
                                        This will allow us to share your profile
                                        across the pallet communities and your
                                        profile will be advertised more broadly.
                                    </Text>
                                </Box>
                                <TalentFormFieldsBox>
                                    <RadioOptions>
                                        <RadioOption
                                            type="button"
                                            selected={
                                                values.isPublicToNetwork ===
                                                true
                                            }
                                            onClick={() =>
                                                setFieldValue(
                                                    "isPublicToNetwork",
                                                    true
                                                )
                                            }
                                        >
                                            <Text
                                                variant="uiLarge600"
                                                width="100%"
                                            >
                                                Yes
                                            </Text>
                                        </RadioOption>
                                        <RadioOption
                                            type="button"
                                            selected={
                                                values.isPublicToNetwork ===
                                                false
                                            }
                                            onClick={() =>
                                                setFieldValue(
                                                    "isPublicToNetwork",
                                                    false
                                                )
                                            }
                                        >
                                            <Text
                                                variant="uiLarge600"
                                                width="100%"
                                            >
                                                No
                                            </Text>
                                        </RadioOption>
                                    </RadioOptions>
                                </TalentFormFieldsBox>
                                <KeyboardButton
                                    type="submit"
                                    shortcut={`${modifierKey} · Enter`}
                                    size="large"
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                    width="100%"
                                >
                                    Continue
                                </KeyboardButton>
                            </TalentFormBox>
                        </Form>
                    )
                }}
            </Formik>
        </Layout>
    )
}

const RadioOptions = styled(FlexRow)`
    width: 100%;
    gap: ${theme.space.small};
`

const RadioOption = styled(UnstyledButton)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border: 1px solid ${theme.colors.gray300};
    border-radius: ${theme.space.small};
    padding: ${theme.space.medium};
    text-align: center;

    ${({ selected }) =>
        selected &&
        `
            background: ${theme.colors.gold000};
            border-color: ${theme.colors.darkGold};
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
                0px 3px 5px rgba(0, 0, 0, 0.04);
    `}

    ${({ selected }) =>
        !selected &&
        `
            &:hover {
                background: ${theme.colors.gray100};
                border-color: ${theme.colors.gray300};
            }
    `}
`
