import { Button, Text } from "@pallet-hq/hegel"
import { CenteredRow } from "components/atoms"
import ThumbnailImage from "components/Image/ThumbnailImage"
import config from "config"
import { Layout } from "@pallet/features/onboardingL2/Layout"
import { useGroupQuery } from "@pallet/features/onboardingL2/useGroupQuery"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { fetchAuthTokenAndBounce } from "utils/auth"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import { checkIfHasApprovedApplicationToCollective } from "@pallet/features/onboardingL2/utils"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"

export const TalentExistingMemberView = () => {
    const palletContext = usePalletContext()
    const router = usePalletRouter()
    const groupQuery = useGroupQuery()
    const userQuery = useUserQuery()

    if (!groupQuery.data?.group?.id || !userQuery.data?.me?.id) return null

    const hasApprovedApplicationToCollective =
        checkIfHasApprovedApplicationToCollective(
            userQuery.data.me,
            groupQuery.data.group.id
        )

    return (
        <Layout hideNav={true}>
            <TalentFormBox>
                <div>
                    <CenteredRow>
                        <ThumbnailImage
                            src={groupQuery.data.group.applylist?.logo?.source}
                            colorSlug=""
                            sizes={["80px"]}
                        />
                    </CenteredRow>
                    <Text variant="titleMedium" my="medium" textAlign="center">
                        {hasApprovedApplicationToCollective
                            ? `You are a member of ${groupQuery.data.group.name}`
                            : `${groupQuery.data.group.applylist.curatorName} has received your application!`}
                    </Text>
                    <Text
                        variant="paragraphLarge"
                        mb="xlarge"
                        textAlign="center"
                    >
                        {hasApprovedApplicationToCollective
                            ? `You will receive intro requests from companies to ${userQuery.data?.me?.email}. If you would like to update your visibility or work status, you can do so from your profile.`
                            : `We’ll update your application status over email. In the mean time, finish building your profile to improve your chances of recruiters seeing your profile.`}
                    </Text>
                    <Button
                        size="large"
                        width="100%"
                        mb="small"
                        onClick={() =>
                            fetchAuthTokenAndBounce(
                                palletContext,
                                router,
                                `${config.CANONICAL_URL}/profile`
                            )
                        }
                    >
                        Edit my profile
                    </Button>
                    <Button
                        size="large"
                        variant="secondary"
                        width="100%"
                        onClick={() => router.push("/jobs")}
                    >
                        Browse the job board
                    </Button>
                </div>
            </TalentFormBox>
        </Layout>
    )
}
