import { Layout } from "@pallet/features/onboardingL2/Layout"
import { notify } from "@pallet-hq/hegel"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUpdateUserMutation } from "@pallet/features/onboardingL2//useUpdateUserMutation"
import { Formik } from "formik"
import * as Yup from "yup"
import styled from "@emotion/styled"
import { KeyboardButton, mq, Tag, Text, theme } from "@pallet-hq/hegel"
import { FlexColumn, FlexRow } from "components/atoms"
import UnstyledButton from "components/Buttons/UnstyledButton"
import { ReactComponent as AnonymousIcon } from "components/icons/ghost-mode.svg"
import { Form, FormikValues, FormikProps } from "formik"
import { useHotkeys } from "react-hotkeys-hook"
import { FiGlobe } from "react-icons/fi"
import { isMacintosh } from "utils/userAgent"
import { PrivacyStatusEnum } from "__generated__/globalTypes"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"
import { TalentFormFieldsBox } from "@pallet/features/onboardingL1/TalentFormFieldsBox"
import { useRef } from "react"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"

export const TalentPrivacyView = () => {
    const { handleNext } = useNavigationControls()
    const [updateUser] = useUpdateUserMutation()
    const modifierKey = isMacintosh() ? "⌘" : "Ctrl"
    const formRef = useRef<FormikProps<FormikValues>>(null)
    const userQuery = useUserQuery()

    useHotkeys("ctrl+enter, cmd+enter", () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    })

    return (
        <Layout>
            <Formik
                initialValues={{
                    privacyStatus: userQuery.data?.me?.privacyStatus,
                }}
                validationSchema={Yup.object().shape({
                    privacyStatus: Yup.string().required("Required"),
                })}
                innerRef={formRef}
                onSubmit={async values => {
                    const { data } = await updateUser({ variables: values })
                    if (data?.updateJobSeeker?.userError) {
                        notify.fail("An error occurred. Please try again.")
                    } else {
                        handleNext()
                    }
                }}
                validateOnMount
            >
                {({ values, setFieldValue, isValid, isSubmitting }) => {
                    return (
                        <Form>
                            <TalentFormBox>
                                <Text variant="titleMedium">
                                    How would you like to appear in the
                                    collective?
                                </Text>
                                <TalentFormFieldsBox>
                                    <PrivacyOptions>
                                        <PrivacyOption
                                            type="button"
                                            selected={
                                                values.privacyStatus ===
                                                PrivacyStatusEnum.PUBLIC
                                            }
                                            onClick={() =>
                                                setFieldValue(
                                                    "privacyStatus",
                                                    PrivacyStatusEnum.PUBLIC
                                                )
                                            }
                                        >
                                            <IconDiv
                                                color={theme.colors.blue100}
                                            >
                                                <FiGlobe
                                                    size={26}
                                                    color={theme.colors.blue700}
                                                />
                                            </IconDiv>
                                            <InfoDiv>
                                                <TitleDiv>
                                                    <Text variant="headlineSmall">
                                                        Keep my profile public
                                                    </Text>
                                                    <Tag
                                                        bg="gold000"
                                                        width="fit-content"
                                                    >
                                                        <Text
                                                            variant="uiTiny500"
                                                            color="gold900"
                                                            textTransform="none"
                                                        >
                                                            Recommended
                                                        </Text>
                                                    </Tag>
                                                </TitleDiv>
                                                <Text variant="paragraphLarge">
                                                    Make my profile and
                                                    information visible to
                                                    anyone viewing my profile.
                                                </Text>
                                            </InfoDiv>
                                        </PrivacyOption>
                                        <PrivacyOption
                                            type="button"
                                            selected={
                                                values.privacyStatus ===
                                                PrivacyStatusEnum.ANONYMOUS
                                            }
                                            onClick={() =>
                                                setFieldValue(
                                                    "privacyStatus",
                                                    PrivacyStatusEnum.ANONYMOUS
                                                )
                                            }
                                        >
                                            <IconDiv
                                                color={theme.colors.gray900}
                                            >
                                                <AnonymousIcon
                                                    width={38}
                                                    height={38}
                                                />
                                            </IconDiv>
                                            <InfoDiv>
                                                <TitleDiv>
                                                    <Text variant="headlineSmall">
                                                        Anonymous
                                                    </Text>
                                                </TitleDiv>
                                                <Text variant="paragraphLarge">
                                                    Keep your profile hidden.
                                                    Recruiters can see your
                                                    profile once you accept
                                                    their intro request.
                                                </Text>
                                            </InfoDiv>
                                        </PrivacyOption>
                                    </PrivacyOptions>
                                </TalentFormFieldsBox>
                                <KeyboardButton
                                    type="submit"
                                    shortcut={`${modifierKey} · Enter`}
                                    size="large"
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                    width="100%"
                                >
                                    Continue
                                </KeyboardButton>
                            </TalentFormBox>
                        </Form>
                    )
                }}
            </Formik>
        </Layout>
    )
}
const PrivacyOptions = styled(FlexColumn)`
    gap: ${theme.space.small};
`

const PrivacyOption = styled(UnstyledButton)`
    display: flex;
    gap: ${theme.space.medium};
    cursor: pointer;
    border: 1px solid ${theme.colors.gray300};
    border-radius: ${theme.space.small};
    padding: ${theme.space.small} ${theme.space.medium};
    text-align: left;
    align-items: center;

    ${({ selected }) =>
        selected &&
        `
            background: ${theme.colors.gold000};
            border-color: ${theme.colors.darkGold};
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
                0px 3px 5px rgba(0, 0, 0, 0.04);
    `}

    ${({ selected }) =>
        !selected &&
        `
            &:hover {
                background: ${theme.colors.gray100};
                border-color: ${theme.colors.gray300};
            }
    `}
`

const IconDiv = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: ${({ color }) => color};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`

const InfoDiv = styled(FlexColumn)`
    gap: ${theme.space.xxsmall};
`

const TitleDiv = styled(FlexRow)`
    gap: ${theme.space.xsmall};
    ${mq({ flexDirection: ["column-reverse", "row"] })};
`
