import styled from "@emotion/styled"
import { mq } from "@pallet-hq/hegel"
import Image, { ImageProps } from "components/Image"

type ThumbnailImageDivProps = {
    sizes: string[]
    borderRadius?: string
}

export type ThumbnailImageProps = ThumbnailImageDivProps &
    Omit<ImageProps, "sizes">

const ThumbnailImage = ({
    sizes,
    borderRadius,
    ...rest
}: ThumbnailImageProps) => {
    return (
        <ThumbnailImageDiv sizes={sizes} borderRadius={borderRadius}>
            <ThumbnailImg
                imgixParams={{ auto: "format,compress" }}
                width={170}
                height={170}
                {...rest}
            />
        </ThumbnailImageDiv>
    )
}

const ThumbnailImageDiv = styled.div`
    ${(props: ThumbnailImageDivProps) =>
        // always going to be a square
        mq({ height: props.sizes, width: props.sizes })}

    border-radius: ${({ borderRadius }: ThumbnailImageDivProps) =>
        borderRadius || "16px"};
    object-fit: fill;
    flex: none;
`

const ThumbnailImg = styled(Image)`
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
`

export default ThumbnailImage
