import {
    TalentPreferencesForm,
    TalentPreferencesFormValues,
} from "@pallet/features/onboardingL1/TalentPreferencesForm"
import { KeyboardButton, notify } from "@pallet-hq/hegel"
import { isMacintosh } from "utils/userAgent"
import * as Yup from "yup"
import { Layout } from "@pallet/features/onboardingL2/Layout"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUpdateUserMutation } from "@pallet/features/onboardingL2/useUpdateUserMutation"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { Formik, FormikProps } from "formik"
import { useRef } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import {
    LinkedinUrlField,
    CurrentCityField,
    SelectedCitiesField,
    OpenToRemoteField,
    SelectedIndustriesField,
    WorkStatusField,
    workStatusOptions,
} from "@pallet/components/fields"
import { convertOptionsToValues } from "components/inputs/Select/utils"

export const TalentPreferencesView = () => {
    const { handleNext } = useNavigationControls()
    const [updateUser] = useUpdateUserMutation()
    const userQuery = useUserQuery()
    const modifierKey = isMacintosh() ? "⌘" : "Ctrl"
    const formRef = useRef<FormikProps<TalentPreferencesFormValues>>(null)

    useHotkeys(
        "ctrl+enter, cmd+enter",
        () => {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
        },
        {
            enableOnTags: ["INPUT", "TEXTAREA"],
        }
    )

    if (!userQuery.data?.me?.id) return null

    return (
        <Layout>
            <Formik<TalentPreferencesFormValues>
                initialValues={{
                    [LinkedinUrlField.fieldName]:
                        userQuery.data?.me?.linkedinUrl ?? "",
                    [CurrentCityField.fieldName]: userQuery.data?.me
                        ?.currentCity
                        ? {
                              label: userQuery.data.me.currentCity.longLabel,
                              value: userQuery.data.me.currentCity.geonameId.toString(),
                              abbreviatedLabel:
                                  userQuery.data.me.currentCity.shortLabel,
                          }
                        : null,
                    [SelectedCitiesField.fieldName]:
                        userQuery.data?.me?.selectedCities.map(city => ({
                            label: city.longLabel,
                            value: city.geonameId.toString(),
                            abbreviatedLabel: city.shortLabel,
                        })) ?? [],
                    [OpenToRemoteField.fieldName]:
                        !!userQuery.data?.me?.isOpenToRemote,
                    [SelectedIndustriesField.fieldName]:
                        userQuery.data?.me?.selectedIndustries.map(
                            industry => ({
                                label: industry.name,
                                value: industry.slug,
                            })
                        ) ?? [],
                    [WorkStatusField.fieldName]:
                        workStatusOptions.find(
                            ({ value }) =>
                                value.toString() ===
                                userQuery.data?.me?.workStatus?.toString()
                        ) ?? null,
                }}
                innerRef={formRef}
                validationSchema={Yup.object().shape({
                    ...LinkedinUrlField.validators.profileNotRequired,
                    ...CurrentCityField.validators.required,
                    ...SelectedCitiesField.validators.required,
                    ...OpenToRemoteField.validators.required,
                    ...SelectedIndustriesField.validators.required,
                    ...WorkStatusField.validators.required,
                })}
                onSubmit={async values => {
                    const { data } = await updateUser({
                        variables: {
                            user: userQuery.data?.me?.slug ?? "",
                            linkedinUrl: values[LinkedinUrlField.fieldName],
                            currentCity: parseInt(
                                values[CurrentCityField.fieldName]!.value
                            ),
                            selectedCities: convertOptionsToValues(
                                values[SelectedCitiesField.fieldName]
                            ).map(value => parseInt(value)),
                            isOpenToRemote: values[OpenToRemoteField.fieldName],
                            selectedIndustries: convertOptionsToValues(
                                values[SelectedIndustriesField.fieldName]
                            ),
                            workStatus:
                                values[WorkStatusField.fieldName]!.value,
                        },
                    })
                    if (data?.updateJobSeeker?.userError) {
                        notify.fail("An error occurred. Please try again.")
                    } else {
                        handleNext()
                    }
                }}
                validateOnMount
            >
                {props => {
                    return (
                        <TalentPreferencesForm
                            formikProps={props}
                            submitButton={
                                <KeyboardButton
                                    type="submit"
                                    shortcut={`${modifierKey} · Enter`}
                                    size="large"
                                    disabled={!props.isValid}
                                    loading={props.isSubmitting}
                                    width="100%"
                                >
                                    Continue
                                </KeyboardButton>
                            }
                        />
                    )
                }}
            </Formik>
        </Layout>
    )
}
