import { KeyboardButton, notify, Text } from "@pallet-hq/hegel"
import { Layout } from "@pallet/features/onboardingL2/Layout"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUpdateUserMutation } from "@pallet/features/onboardingL2//useUpdateUserMutation"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { Form, Formik, FormikProps, FormikValues } from "formik"
import { useRef } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { isMacintosh } from "utils/userAgent"
import * as Yup from "yup"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"
import { BioField } from "@pallet/components/fields/Bio"
import { JobSearchStageField } from "@pallet/components/fields/JobSearchStage"
import { USWorkAuthorizationField } from "@pallet/components/fields/USWorkAuthorization"
import { USRequiresSponsorshipField } from "@pallet/components/fields/USRequiresSponsorship"

export const TalentMoreDetails1View = () => {
    const { handleNext } = useNavigationControls()
    const [updateUser] = useUpdateUserMutation()
    const userQuery = useUserQuery()
    const modifierKey = isMacintosh() ? "⌘" : "Ctrl"
    const formRef = useRef<FormikProps<FormikValues>>(null)

    useHotkeys(
        "ctrl+enter, cmd+enter",
        () => {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
        },
        {
            enableOnTags: ["TEXTAREA"],
        }
    )

    if (!userQuery.data?.me?.id) return null

    return (
        <Layout>
            <Formik
                initialValues={{
                    [BioField.fieldName]: userQuery.data?.me?.bio || "",
                    [JobSearchStageField.fieldName]:
                        userQuery.data?.me?.workStatus || "",
                    [USWorkAuthorizationField.fieldName]:
                        userQuery.data?.me?.authorizedForUs,
                    [USRequiresSponsorshipField.fieldName]:
                        userQuery.data?.me?.requiresSponsorshipForUs,
                }}
                innerRef={formRef}
                validationSchema={Yup.object().shape({
                    ...BioField.validators.required,
                    ...JobSearchStageField.validators.required,
                    ...USWorkAuthorizationField.validators.required,
                    ...USRequiresSponsorshipField.validators.required,
                })}
                onSubmit={async values => {
                    const { data } = await updateUser({ variables: values })
                    if (data?.updateJobSeeker?.userError) {
                        notify.fail("An error occurred. Please try again.")
                    } else {
                        handleNext()
                    }
                }}
                validateOnMount
            >
                {({ isValid, isSubmitting }) => {
                    return (
                        <Form>
                            <TalentFormBox>
                                <Text variant="titleMedium">
                                    Tell us more about you
                                </Text>
                                <BioField width="100%" autoFocus />
                                <JobSearchStageField />
                                <USWorkAuthorizationField />
                                <USRequiresSponsorshipField />
                                <KeyboardButton
                                    type="submit"
                                    shortcut={`${modifierKey} · Enter`}
                                    size="large"
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                    width="100%"
                                >
                                    Continue
                                </KeyboardButton>
                            </TalentFormBox>
                        </Form>
                    )
                }}
            </Formik>
        </Layout>
    )
}
