import { getApolloInstance } from "apollo/client"
import { NextRouter } from "next/router"
import { FC } from "react"
import { ApplyToCollective } from "__generated__/ApplyToCollective"
import { GetCandidateApplicationGroupData } from "__generated__/GetCandidateApplicationGroupData"
import {
    GetCandidateApplicationUserData,
    GetCandidateApplicationUserData_me,
} from "__generated__/GetCandidateApplicationUserData"
import {
    ApplicationStatusEnum,
    CandidateApplicationStepEnum,
} from "__generated__/globalTypes"
import { APPLY_TO_COLLECTIVE } from "@pallet/features/onboardingL2/useApplyToCollectiveMutation"
import { GET_CANDIDATE_APPLICATION_GROUP_DATA } from "@pallet/features/onboardingL2/useGroupQuery"
import { GET_CANDIDATE_APPLICATION_DATA } from "@pallet/features/onboardingL2/useUserQuery"
import { TalentDoneView } from "@pallet/features/onboardingL2/TalentDoneView"
import { TalentPrivacyView } from "@pallet/features/onboardingL2/TalentPrivacyView"
import { TalentWelcomeView } from "@pallet/features/onboardingL2/TalentWelcomeView"
import { TalentNetworkConsentView } from "@pallet/features/onboardingL2/TalentNetworkConsentView"
import { TalentMoreDetails1View } from "@pallet/features/onboardingL2/TalentMoreDetails1View"
import { TalentMoreDetails2View } from "@pallet/features/onboardingL2/TalentMoreDetails2View"
import { TalentPreferencesView } from "@pallet/features/onboardingL2/TalentPreferencesView"
import { TalentWorkDetailsView } from "@pallet/features/onboardingL2/TalentWorkDetailsView"

export type CandidateOnboardingStepInfo = {
    slug: string
    Component: FC
    authExempt?: boolean
}

// An array of steps corresponding to each view in the candidate onboarding
// application flow. For each step, there is a unique slug, React component to
// be rendered, whether the step requires auth, and whether it is specific to
// anonymous or public users.
export const stepConfig = [
    {
        slug: "welcome",
        Component: TalentWelcomeView,
        authExempt: true,
    },
    {
        slug: "privacy",
        Component: TalentPrivacyView,
    },
    {
        slug: "network",
        Component: TalentNetworkConsentView,
    },
    {
        slug: "preferences",
        Component: TalentPreferencesView,
    },
    {
        slug: "work",
        Component: TalentWorkDetailsView,
    },
    {
        slug: "details1",
        Component: TalentMoreDetails1View,
    },
    {
        slug: "details2",
        Component: TalentMoreDetails2View,
    },
    {
        slug: "done",
        Component: TalentDoneView,
    },
] as CandidateOnboardingStepInfo[]

/**
 * Checks if a specified user has submitted an application to the CURRENT
 * collective.
 */
export const checkIfHasApplicationToCollective = (
    user: GetCandidateApplicationUserData_me,
    groupId: string
) => {
    return Boolean(
        user.candidates?.edges.find(
            candidate =>
                groupId === candidate.node.group.id &&
                candidate.node.applicationStatusEnum !==
                    ApplicationStatusEnum.INCOMPLETE &&
                candidate.node.applicationStatusEnum !==
                    ApplicationStatusEnum.NOT_STARTED
        )
    )
}

/**
 * Checks if a specified user has an approved application to the CURRENT
 * collective.
 */
export const checkIfHasApprovedApplicationToCollective = (
    user: GetCandidateApplicationUserData_me,
    groupId: string
) => {
    return Boolean(
        user.candidates?.edges.find(
            candidate =>
                groupId === candidate.node.group.id &&
                candidate.node.applicationStatusEnum ===
                    ApplicationStatusEnum.APPROVED
        )
    )
}

/**
 * Helper method triggered when a user starts their application.
 * Their application status will be set to INCOMPLETE.
 */
export const startApplication = (palletSlug: string) => {
    const apolloClient = getApolloInstance()

    return apolloClient.mutate<ApplyToCollective>({
        mutation: APPLY_TO_COLLECTIVE,
        variables: {
            palletSlug,
            applicationStep: CandidateApplicationStepEnum.START,
        },
    })
}

/**
 * Helper method used after logging in to check the state of the current user.
 * If they have already filled out an application or have access to the
 * collective, redirect them to the corresponding page.
 */
export const handleAfterLogin = async (
    router: NextRouter,
    palletSlug: string
) => {
    const apolloClient = getApolloInstance()
    const userQuery = await apolloClient.query<GetCandidateApplicationUserData>(
        {
            query: GET_CANDIDATE_APPLICATION_DATA,
            fetchPolicy: "network-only",
        }
    )
    const groupQuery =
        await apolloClient.query<GetCandidateApplicationGroupData>({
            query: GET_CANDIDATE_APPLICATION_GROUP_DATA,
            variables: { pallet: palletSlug },
            fetchPolicy: "network-only",
        })

    const hasApplicationToCollective = checkIfHasApplicationToCollective(
        userQuery.data!.me!,
        groupQuery.data!.group!.id
    )

    if (groupQuery.data?.group?.canViewDrops) {
        await router.push("/talent/drops")
    } else if (hasApplicationToCollective) {
        await router.push("/talent/profile")
    } else {
        startApplication(palletSlug)
    }
}
