import { css } from "@emotion/react"
import { Box, theme } from "@pallet-hq/hegel"
import { TalentStylizedOnboardingMessageEmphasizedText } from "@pallet/features/onboardingL1/TalentStylizedOnboardingMessageEmphasizedText"
import { TalentStylizedOnboardingMessagePanel } from "@pallet/features/onboardingL1/TalentStylizedOnboardingMessagePanel"
import { TalentStylizedOnboardingMessageText } from "@pallet/features/onboardingL1/TalentStylizedOnboardingMessageText"
import { NavBar } from "@pallet/features/onboardingL2/NavBar"
import { useGroupQuery } from "@pallet/features/onboardingL2/useGroupQuery"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { DesktopOnly } from "components/atoms"
import { ReactNode } from "react"

type LayoutProps = {
    heading?: string
    subheading?: string
    skipAuth?: boolean
    hideNav?: boolean
    disableNext?: boolean
    children: ReactNode
}

export const Layout = (props: LayoutProps) => {
    const userQuery = useUserQuery()
    const groupQuery = useGroupQuery()
    const { activeStep } = useNavigationControls()

    if (!groupQuery.data?.group?.id) return null
    if (!activeStep.authExempt && !userQuery.data?.me?.id && !props.skipAuth)
        return null
    const heading = props.heading ?? `Apply to ${groupQuery.data.group.name} `
    const subheading =
        props.subheading ?? groupQuery.data.group.candidateOnboardingDescription

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="flex-start"
            padding="0px"
            height="100%"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
                width={["100%", "60%"]}
                height="100%"
            >
                <Box
                    css={css`
                        position: sticky;
                        inset: 0;
                        visibility: ${props.hideNav ? "hidden" : "visible"};
                        width: 100%;
                    `}
                >
                    <NavBar />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding={`${theme.space.xxlarge} ${theme.space.xlarge}`}
                    gridGap={theme.space.large}
                    height="100%"
                    width="100%"
                    overflowY="auto"
                    css={css`
                        isolation: isolate;
                        box-sizing: border-box;
                        & > form {
                            margin: auto 0px;
                        }
                    `}
                >
                    {props.children}
                </Box>
            </Box>
            <DesktopOnly css={{ width: "40%", height: "100%" }}>
                <TalentStylizedOnboardingMessagePanel
                    message={
                        <TalentStylizedOnboardingMessageText>
                            {heading}
                            <TalentStylizedOnboardingMessageEmphasizedText>
                                {subheading}
                            </TalentStylizedOnboardingMessageEmphasizedText>
                        </TalentStylizedOnboardingMessageText>
                    }
                />
            </DesktopOnly>
        </Box>
    )
}
