import styled from "@emotion/styled"
import { theme } from "@pallet-hq/hegel"
import UnstyledButton from "components/Buttons/UnstyledButton"
import { FiArrowLeft, FiArrowRight } from "react-icons/fi"

type NavButtonProps = {
    direction: "previous" | "next"
    handleClick: () => void
    disabled: boolean
}

export const NavButton = ({
    direction,
    handleClick,
    disabled,
}: NavButtonProps) => {
    const Icon = direction === "previous" ? FiArrowLeft : FiArrowRight

    return (
        <Wrapper
            onClick={() => {
                if (!disabled) handleClick()
            }}
            disabled={disabled}
        >
            <Icon
                size={24}
                stroke={disabled ? theme.colors.gray500 : theme.colors.gray900}
            />
        </Wrapper>
    )
}

const Wrapper = styled(UnstyledButton)`
    height: 100%;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`
