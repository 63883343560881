import { BaseModal, theme } from "@pallet-hq/hegel"
import { TalentAccountView } from "@pallet/features/onboardingL2/TalentAccountView"
import { TalentExistingMemberView } from "@pallet/features/onboardingL2/TalentExistingMemberView"
import { useCandidateApplication } from "@pallet/features/onboardingL2/useCandidateApplication"
import { useGroupQuery } from "@pallet/features/onboardingL2/useGroupQuery"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { checkIfHasApplicationToCollective } from "@pallet/features/onboardingL2/utils"
import { useEffect } from "react"
import useAuthContext from "state/useAuthContext"
import useHelpMenu from "state/useHelpMenu"

export const Modal = () => {
    const { setIsVisible } = useHelpMenu()
    const { activeStep, isReady, isCheckingAuth } = useNavigationControls()
    const { isModalOpen, hideModal } = useCandidateApplication()
    const { authed } = useAuthContext()
    const userQuery = useUserQuery()
    const groupQuery = useGroupQuery()
    const hasApplicationToCollective =
        userQuery.data?.me?.id &&
        groupQuery.data?.group?.id &&
        checkIfHasApplicationToCollective(
            userQuery.data.me,
            groupQuery.data.group.id
        )

    useEffect(() => {
        // hides Intercom when modal is visible
        setIsVisible(!isModalOpen)
    }, [setIsVisible, isModalOpen])

    if (!isReady) return null

    const getModalComponent = () => {
        if (isCheckingAuth || (!activeStep.authExempt && !authed)) {
            return <TalentAccountView />
        } else if (hasApplicationToCollective) {
            return <TalentExistingMemberView />
        } else {
            return <activeStep.Component />
        }
    }

    return (
        <BaseModal
            isOpen={isModalOpen}
            hideModal={hideModal}
            style={{
                overlay: {
                    backgroundColor: theme.colors.black4,
                    zIndex: 2,
                },
                content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    width: "calc(100% - 48px)",
                    height: "calc(100% - 48px)",
                    maxWidth: "1392px",
                    maxHeight: "860px",
                    borderRadius: "16px",
                    boxShadow:
                        "0px 1px 6px rgba(0, 0, 0, 0.16), 0px 6px 20px rgba(0, 0, 0, 0.2)",
                },
            }}
            shouldCloseOnEsc={false}
        >
            {getModalComponent()}
        </BaseModal>
    )
}
