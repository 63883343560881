import { ReactNode } from "react"
import { theme, Banner, Text } from "@pallet-hq/hegel"
import { css } from "@emotion/react"
import { CurrentJobTypeField } from "@pallet/components/fields/CurrentJobType"
import { CurrentExperienceField } from "@pallet/components/fields/CurrentExperience"
import { HasNoExperienceField } from "@pallet/components/fields/HasNoExperience"
import { CurrentTitleField } from "@pallet/components/fields/CurrentTitle"
import { CurrentCompanyField } from "@pallet/components/fields/CurrentCompany"
import { FiInfo } from "react-icons/fi"
import { Form, FormikProps } from "formik"
import { Option } from "@pallet-hq/hegel/lib/UIComponents/SelectModules/Types"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"
import { TalentFormFieldsBox } from "@pallet/features/onboardingL1/TalentFormFieldsBox"

export type TalentWorkDetailsFormValues = {
    [CurrentJobTypeField.fieldName]: Option | null
    [CurrentExperienceField.fieldName]: number | null
    [HasNoExperienceField.fieldName]: boolean
    [CurrentTitleField.fieldName]: string
    [CurrentCompanyField.fieldName]: Option | null
}

type TalentWorkDetailsFormProps = {
    formikProps: FormikProps<TalentWorkDetailsFormValues>
    submitButton: ReactNode
}

export const TalentWorkDetailsForm = (props: TalentWorkDetailsFormProps) => {
    return (
        <Form>
            <TalentFormBox>
                <Text variant="titleMedium">Work Details</Text>
                <TalentFormFieldsBox>
                    <CurrentJobTypeField width="100%" />
                    <CurrentExperienceField width="100%" />
                    <HasNoExperienceField />
                    <CurrentTitleField
                        width="100%"
                        disabled={
                            props.formikProps.values[
                                HasNoExperienceField.fieldName
                            ]
                        }
                    />
                    <CurrentCompanyField
                        width="100%"
                        disabled={
                            props.formikProps.values[
                                HasNoExperienceField.fieldName
                            ]
                        }
                    />
                    <Banner
                        icon={FiInfo}
                        label="You can add additional work experience to your profile later"
                        css={css`
                            background: ${theme.colors.gray100};

                            div,
                            svg {
                                color: ${theme.colors.gray600};
                                stroke: ${theme.colors.gray600};
                            }
                        `}
                    />
                </TalentFormFieldsBox>
                {props.submitButton}
            </TalentFormBox>
        </Form>
    )
}
