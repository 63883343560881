import { gql, useQuery } from "@apollo/client"
import useAuthContext from "state/useAuthContext"
import { GetCandidateApplicationUserData } from "__generated__/GetCandidateApplicationUserData"

export const CANDIDATE_APPLICATION_DATA_FRAGMENT = gql`
    fragment CandidateApplicationData_user on BaseUserInterface {
        id
        email
        bio
        linkedinUrl
        profileImageFile(retrieveSize: true) {
            name
            size
            source
        }
        firstName
        lastName
        privacyStatus
        workStatus
        customHeadline
        authorizedForUs
        requiresSponsorshipForUs
        preferencesSummary
        yearsOfExperience
        isOpenToRemote
        hasNoWorkExperience
        currentTitle
        currentJobStartDate
        currentJobEndDate
        isUnemployed
        isLaidOff
        isPublicToNetwork
        recentEmployer {
            id
            slug
            name
            image
        }
        currentCity {
            id
            geonameId
            name
            longLabel
            shortLabel
        }
        currentJobType {
            id
            slug
            name
            shortLabel
            longLabel
            experienceName
        }
        selectedJobTypes {
            edges {
                node {
                    id
                    name
                    slug
                    experienceName
                }
            }
        }
        selectedWorkTypes {
            id
            name
            slug
        }
        selectedIndustries {
            id
            name
            slug
        }
        selectedSalary {
            amount
            currency
        }
        selectedCities {
            id
            geonameId
            name
            longLabel
            shortLabel
        }
        hiddenCompanies {
            edges {
                node {
                    id
                    name
                    slug
                }
            }
        }
        candidates {
            edges {
                node {
                    id
                    applicationStatusEnum
                    group {
                        id
                    }
                }
            }
        }
    }
`

export const GET_CANDIDATE_APPLICATION_DATA = gql`
    query GetCandidateApplicationUserData {
        me {
            id
            slug
            ...CandidateApplicationData_user
        }
        supportedCurrencies {
            label
            value
        }
        industries {
            id
            name
            slug
        }
    }
    ${CANDIDATE_APPLICATION_DATA_FRAGMENT}
`

export const useUserQuery = () => {
    const { authed } = useAuthContext()
    return useQuery<GetCandidateApplicationUserData>(
        GET_CANDIDATE_APPLICATION_DATA,
        { skip: !authed, ssr: false }
    )
}
