import { Box, Text, Theme, hexToHSL } from "@pallet-hq/hegel"
import { useTheme } from "@emotion/react"

export const NumberedList = ({
    items,
}: {
    items: { heading: string; description?: string }[]
}) => {
    const theme = useTheme() as Theme
    const [h, s] = hexToHSL(theme.colors.primary)
    const lightVariant = `hsl(${h}, ${s}%, 90%);`
    const darkVariant = `hsl(${h}, ${s}%, 10%);`

    return (
        <Box display="flex" flexDirection="column" gridGap="medium">
            {items.map((item, index) => (
                <Box key={index} display="flex" gridGap="small">
                    <Box
                        display="flex"
                        justifyContent="center"
                        width="32px"
                        height="32px"
                        borderRadius="100%"
                        alignItems="center"
                        flexShrink={0}
                        color={darkVariant}
                        backgroundColor={lightVariant}
                    >
                        <Text variant="headlineSmall">{index + 1}</Text>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gridGap="xxsmall"
                    >
                        <Text variant="headlineSmall">{item.heading}</Text>
                        <Text variant="paragraphLarge" color="gray600">
                            {item.description}
                        </Text>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}
