import { useEffect } from "react"
import { KeyboardButton, Text } from "@pallet-hq/hegel"
import { NumberedList } from "@pallet/components/NumberedList"
import ThumbnailImage from "components/Image/ThumbnailImage"
import { Layout } from "@pallet/features/onboardingL2/Layout"
import { useApplyToCollectiveMutation } from "@pallet/features/onboardingL2/useApplyToCollectiveMutation"
import { useGroupQuery } from "@pallet/features/onboardingL2/useGroupQuery"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { checkIfHasApplicationToCollective } from "@pallet/features/onboardingL2/utils"
import { useHotkeys } from "react-hotkeys-hook"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import { isMacintosh } from "utils/userAgent"
import { CandidateApplicationStepEnum } from "__generated__/globalTypes"
import mixpanel from "mixpanel-browser"
import { EVENTS } from "constants/events"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"

export const TalentWelcomeView = () => {
    const palletContext = usePalletContext()
    const { handleNext } = useNavigationControls()
    const groupQuery = useGroupQuery()
    const userQuery = useUserQuery()
    const router = usePalletRouter()
    const [applyToCollective] = useApplyToCollectiveMutation()
    const modifierKey = isMacintosh() ? "⌘" : "Ctrl"

    useEffect(() => {
        mixpanel.track(EVENTS.COLLECTIVE_START_APPLICATION, {
            pallet: palletContext.pallet,
        })
    })

    const startApplication = () => {
        applyToCollective({
            variables: {
                applicationStep: CandidateApplicationStepEnum.START,
            },
        })
    }

    const handleClick = () => {
        if (!userQuery.data?.me?.id) return handleNext()

        const canViewDrops = groupQuery.data!.group!.canViewDrops
        const hasExistingApplication = checkIfHasApplicationToCollective(
            userQuery.data!.me!,
            groupQuery.data!.group!.id
        )

        if (canViewDrops) {
            router.push("/talent/drops")
        } else if (hasExistingApplication) {
            router.push("/talent/profile")
        } else {
            startApplication()
            handleNext()
        }
    }

    useHotkeys("ctrl+enter, cmd+enter", () => {
        handleClick()
    })

    if (!groupQuery.data?.group?.id) return null

    return (
        <Layout
            heading="Join a collective and get hired "
            subheading="by a curated set of companies"
            hideNav={true}
        >
            <TalentFormBox>
                <div>
                    <ThumbnailImage
                        src={groupQuery.data.group.applylist.logo?.source}
                        colorSlug=""
                        defaultColor="primary"
                        sizes={["80px"]}
                    />
                    <Text variant="titleMedium" mt="medium" mb="small">
                        Welcome to {groupQuery.data.group.name}
                    </Text>
                    <Text variant="paragraphLarge" mb="large">
                        {groupQuery.data.group.description}
                    </Text>
                    <NumberedList
                        items={[
                            {
                                heading: "Apply to the collective",
                                description:
                                    "Create your account with Pallet to join the Collective.",
                            },
                            {
                                heading:
                                    "Get connected to curated opportunities",
                                description:
                                    "Discover a whole host of new companies explicitly approved by me",
                            },
                            {
                                heading: "All handled on your terms",
                                description:
                                    "Show and hide yourself from specific companies. Reject or accept intro requests, all on your own terms.",
                            },
                        ]}
                    />
                    <KeyboardButton
                        size="large"
                        mt="large"
                        width="100%"
                        onClick={handleClick}
                        shortcut={`${modifierKey} · Enter`}
                    >
                        Get started
                    </KeyboardButton>
                </div>
            </TalentFormBox>
        </Layout>
    )
}
