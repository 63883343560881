import { gql, useMutation } from "@apollo/client"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { ApplyToCollective } from "__generated__/ApplyToCollective"

export const APPLY_TO_COLLECTIVE = gql`
    mutation ApplyToCollective(
        $palletSlug: String!
        $applicationStep: CandidateApplicationStepEnum!
        $referredBy: String
    ) {
        applyToCollective(
            palletSlug: $palletSlug
            applicationStep: $applicationStep
            referredBy: $referredBy
        ) {
            candidate {
                id
            }
            validationError {
                message
            }
        }
    }
`

export const useApplyToCollectiveMutation = () => {
    const { pallet } = usePalletContext()
    return useMutation<ApplyToCollective>(APPLY_TO_COLLECTIVE, {
        variables: {
            palletSlug: pallet,
        },
    })
}
