import { gql, useMutation } from "@apollo/client"
import useAuthContext from "state/useAuthContext"
import { UpdateCandidateApplicationData } from "__generated__/UpdateCandidateApplicationData"
import { CANDIDATE_APPLICATION_DATA_FRAGMENT } from "@pallet/features/onboardingL2/useUserQuery"

export const UPDATE_CANDIDATE_APPLICATION_DATA = gql`
    mutation UpdateCandidateApplicationData(
        $userSlug: String!
        $firstName: String
        $lastName: String
        $profileImage: Upload
        $currentJobType: String
        $currentCity: Int
        $bio: String
        $workStatus: String
        $authorizedForUs: Boolean
        $requiresSponsorshipForUs: Boolean
        $linkedinUrl: String
        $instagramUrl: String
        $privacyStatus: String
        $hiddenCompanies: [String]
        $selectedCities: [Int!]
        $selectedJobTypes: [String!]
        $selectedWorkTypes: [String!]
        $selectedIndustries: [String!]
        $selectedSalary: Int
        $selectedSalaryCurrency: String
        $isOpenToRemote: Boolean
        $preferencesSummary: String
        $yearsOfExperience: Int
        $hasNoWorkExperience: Boolean
        $customHeadline: String
        $currentTitle: String
        $recentEmployer: String
        $currentJobStartDate: Date
        $currentJobEndDate: Date
        $isUnemployed: Boolean
        $isLaidOff: Boolean
        $isPublicToNetwork: Boolean
    ) {
        updateJobSeeker(
            userSlug: $userSlug
            firstName: $firstName
            lastName: $lastName
            profileImage: $profileImage
            currentJobType: $currentJobType
            currentCity: $currentCity
            bio: $bio
            workStatus: $workStatus
            authorizedForUs: $authorizedForUs
            requiresSponsorshipForUs: $requiresSponsorshipForUs
            linkedinUrl: $linkedinUrl
            instagramUrl: $instagramUrl
            privacyStatus: $privacyStatus
            hiddenCompanies: $hiddenCompanies
            selectedCities: $selectedCities
            selectedJobTypes: $selectedJobTypes
            selectedWorkTypes: $selectedWorkTypes
            selectedIndustries: $selectedIndustries
            selectedSalary: $selectedSalary
            selectedSalaryCurrency: $selectedSalaryCurrency
            isOpenToRemote: $isOpenToRemote
            preferencesSummary: $preferencesSummary
            yearsOfExperience: $yearsOfExperience
            hasNoWorkExperience: $hasNoWorkExperience
            currentTitle: $currentTitle
            recentEmployer: $recentEmployer
            currentJobStartDate: $currentJobStartDate
            currentJobEndDate: $currentJobEndDate
            isUnemployed: $isUnemployed
            isLaidOff: $isLaidOff
            isPublicToNetwork: $isPublicToNetwork
            customHeadline: $customHeadline
        ) {
            user {
                id
                ...CandidateApplicationData_user
            }
            userError {
                message
            }
        }
    }
    ${CANDIDATE_APPLICATION_DATA_FRAGMENT}
`

export const useUpdateUserMutation = () => {
    const { user } = useAuthContext()
    return useMutation<UpdateCandidateApplicationData>(
        UPDATE_CANDIDATE_APPLICATION_DATA,
        {
            variables: {
                userSlug: user?.slug,
            },
        }
    )
}
