import { TRACK_EVENTS } from "constants/events"
import constate from "constate"
import mixpanel from "mixpanel-browser"
import { useEffect, useState } from "react"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import { stepConfig } from "@pallet/features/onboardingL2/utils"
import { useCandidateApplication } from "@pallet/features/onboardingL2/useCandidateApplication"
import { useGroupQuery } from "@pallet/features/onboardingL2/useGroupQuery"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"

export const [NavigationControlsProvider, useNavigationControls] = constate(
    () => {
        const router = usePalletRouter()
        const userQuery = useUserQuery()
        const groupQuery = useGroupQuery()
        const { pallet } = usePalletContext()
        const { isModalOpen } = useCandidateApplication()

        const [activeIndex, setActiveIndex] = useState(0)
        const [isReady, setIsReady] = useState(false)
        const [isCheckingAuth, setIsCheckingAuth] = useState(false)
        const totalSteps = stepConfig.length
        const activeStep = stepConfig[activeIndex]
        const canHandleNext = activeIndex < totalSteps - 1
        const canHandlePrevious = activeIndex > 0

        const handlePrevious = () => {
            if (canHandlePrevious) {
                setActiveIndex(activeIndex => activeIndex - 1)
                router.push(
                    {
                        query: {
                            ...router.query,
                            step: stepConfig[activeIndex - 1].slug,
                        },
                    },
                    undefined,
                    { shallow: true }
                )
            }
        }

        const handleNext = () => {
            if (canHandleNext) {
                const nextStep = stepConfig[activeIndex + 1].slug
                setActiveIndex(activeIndex => activeIndex + 1)
                router.push(
                    {
                        query: {
                            ...router.query,
                            step: nextStep,
                        },
                    },
                    undefined,
                    { shallow: true }
                )
                mixpanel.track(
                    TRACK_EVENTS.CANDIDATE_APPLICATION_STEP_COMPLETED,
                    {
                        applylist_slug: pallet,
                        next_step: nextStep,
                        current_step: activeStep.slug,
                    }
                )
            }
        }

        /**
         * Set the active step based on the current `?step={slug}` url parameter
         */
        const restoreCurrentStepByUrl = () => {
            if (!router.isReady || userQuery.loading || !isModalOpen) return
            const stepBySlug = stepConfig.findIndex(
                step => step.slug === router.query.step
            )
            if (stepBySlug > -1) setActiveIndex(stepBySlug)
            else setActiveIndex(0)
            setIsReady(true)
        }

        /**
         * Redirect to the drops or talent profile page if the user already
         * has access to the collective or submitted an application.
         */
        const redirectIfHasApplicationOrAccess = () => {
            if (
                isModalOpen &&
                !activeStep.authExempt &&
                userQuery.data?.me?.id &&
                groupQuery.data?.group?.id
            ) {
                const canViewDrops = groupQuery.data?.group?.canViewDrops
                if (canViewDrops) {
                    router.push("/talent/drops")
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(restoreCurrentStepByUrl, [
            router.isReady,
            router.query,
            userQuery.loading,
            isModalOpen,
        ])

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(redirectIfHasApplicationOrAccess, [
            userQuery.data,
            groupQuery.data,
            activeStep,
            isModalOpen,
        ])

        return {
            activeStep,
            activeIndex,
            totalSteps,
            handlePrevious,
            handleNext,
            canHandlePrevious,
            canHandleNext,
            isReady,
            setActiveIndex,
            isCheckingAuth,
            setIsCheckingAuth,
        }
    }
)
