import styled from "@emotion/styled"
import { mq, theme } from "@pallet-hq/hegel"
import Image, { ImageProps } from "components/Image"
import { FiImage } from "react-icons/fi"

type BackgroundImageDivProps = {
    containerHeight: string[]
}

type BlurHeaderImageProps = BackgroundImageDivProps & ImageProps

const BlurHeaderImage = ({
    containerHeight,
    imgixParams,
    src,
    ...rest
}: BlurHeaderImageProps) => {
    return (
        <BackgroundImageDiv containerHeight={containerHeight}>
            {src ? (
                <>
                    <BackgroundImg
                        width={1400}
                        height={200}
                        imgixParams={{
                            fit: "crop",
                            crop: "focalpoint",
                            "fp-y": 0.5,
                            "fp-x": 0.5,
                            auto: "compress",
                            ...imgixParams,
                        }}
                        src={src}
                        {...rest}
                    />
                </>
            ) : (
                <EmptyBackground>
                    <FiImage size={24} stroke={theme.colors.gray600} />
                </EmptyBackground>
            )}
        </BackgroundImageDiv>
    )
}

export default BlurHeaderImage

const BackgroundImageDiv = styled.div`
    ${(props: BackgroundImageDivProps) =>
        mq({ height: props.containerHeight })};
    position: relative;
    width: 100%;
    overflow: hidden;
    &:hover button {
        display: block;
    }
`

const EmptyBackground = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    box-sizing: border-box;
    z-index: -1;
    border-bottom: 1px solid ${theme.colors.gray400};
`

const BackgroundImg = styled(Image)`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
`
