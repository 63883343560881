import { KeyboardButton, notify, Text } from "@pallet-hq/hegel"
import { Layout } from "@pallet/features/onboardingL2/Layout"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUpdateUserMutation } from "@pallet/features/onboardingL2//useUpdateUserMutation"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { Form, Formik, FormikProps, FormikValues } from "formik"
import { useRef } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { isMacintosh } from "utils/userAgent"
import * as Yup from "yup"
import SalaryAndCurrencyInput from "components/inputs/SalaryAndCurrencyInput"
import {
    convertOptionsToSlugs,
    getSelectOptions2,
} from "components/inputs/Select/utils"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"
import { ProfilePhotoField } from "@pallet/components/fields/ProfilePhoto"
import { PreferencesSummaryField } from "@pallet/components/fields/PreferencesSummary"
import { BlockedCompaniesField } from "@pallet/components/fields/BlockedCompanies"
import { useApplyToCollectiveMutation } from "@pallet/features/onboardingL2/useApplyToCollectiveMutation"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { CandidateApplicationStepEnum } from "__generated__/globalTypes"
import mixpanel from "mixpanel-browser"
import { EVENTS } from "constants/events"

export const TalentMoreDetails2View = () => {
    const palletContext = usePalletContext()
    const { handleNext } = useNavigationControls()
    const [updateUser] = useUpdateUserMutation()
    const userQuery = useUserQuery()
    const modifierKey = isMacintosh() ? "⌘" : "Ctrl"
    const formRef = useRef<FormikProps<FormikValues>>(null)
    const [applyToCollective] = useApplyToCollectiveMutation()

    useHotkeys(
        "ctrl+enter, cmd+enter",
        () => {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
        },
        {
            enableOnTags: ["INPUT", "TEXTAREA"],
        }
    )

    if (!userQuery.data?.me?.id) return null

    return (
        <Layout>
            <Formik
                initialValues={{
                    [ProfilePhotoField.fieldName]: undefined,
                    [PreferencesSummaryField.fieldName]:
                        userQuery.data?.me?.preferencesSummary || "",
                    selectedSalary:
                        userQuery.data?.me?.selectedSalary?.amount || undefined,
                    selectedSalaryCurrency:
                        userQuery.data?.supportedCurrencies?.find(
                            currency =>
                                currency.label ===
                                (userQuery.data?.me?.selectedSalary?.currency ??
                                    "USD")
                        ),
                    [BlockedCompaniesField.fieldName]: userQuery.data?.me
                        ?.hiddenCompanies?.edges
                        ? getSelectOptions2(
                              userQuery.data.me.hiddenCompanies.edges,
                              "slug",
                              "name"
                          )
                        : [],
                }}
                innerRef={formRef}
                validationSchema={Yup.object().shape({
                    ...ProfilePhotoField.validators.notRequired,
                    ...PreferencesSummaryField.validators.required,
                    selectedSalary: Yup.number()
                        .nullable()
                        .positive("Please enter a valid salary.")
                        .integer("Must not use cent values.")
                        .max(1e10, "Please enter a valid salary."),
                    selectedSalaryCurrency: Yup.object().required("Required"),
                    ...BlockedCompaniesField.validators.notRequired,
                })}
                onSubmit={async values => {
                    const { data } = await updateUser({
                        variables: {
                            ...values,
                            selectedSalary: values.selectedSalary
                                ? parseInt(
                                      values.selectedSalary as unknown as string
                                  )
                                : null,
                            selectedSalaryCurrency:
                                values.selectedSalaryCurrency?.value,
                            [BlockedCompaniesField.fieldName]:
                                convertOptionsToSlugs(values.hiddenCompanies),
                        },
                    })
                    if (data?.updateJobSeeker?.userError) {
                        notify.fail("An error occurred. Please try again.")
                    } else {
                        const { data } = await applyToCollective({
                            variables: {
                                applicationStep:
                                    CandidateApplicationStepEnum.SUBMIT,
                            },
                        })
                        if (
                            data?.applyToCollective?.candidate?.id &&
                            !data.applyToCollective.validationError
                        ) {
                            mixpanel.track(
                                EVENTS.COLLECTIVE_SUBMIT_APPLICATION,
                                {
                                    pallet: palletContext.pallet,
                                }
                            )
                            handleNext()
                        } else {
                            notify.fail(
                                "An error occurred while submitting your application. Please try again."
                            )
                        }
                    }
                }}
                validateOnMount
            >
                {({ isValid, isSubmitting }) => {
                    return (
                        <Form>
                            <TalentFormBox>
                                <ProfilePhotoField
                                    defaultFile={
                                        userQuery.data?.me?.profileImageFile ||
                                        undefined
                                    }
                                    sideDescription="optional"
                                />
                                <Text variant="titleMedium">
                                    Tell us more about you
                                </Text>
                                <PreferencesSummaryField
                                    autoFocus={true}
                                    width="100%"
                                />
                                <SalaryAndCurrencyInput
                                    label="Expected Salary"
                                    description=""
                                    supportedCurrencies={
                                        userQuery.data?.supportedCurrencies ||
                                        []
                                    }
                                    sideDescription="optional"
                                />
                                <BlockedCompaniesField
                                    sideDescription="optional"
                                    width="100%"
                                />
                                <KeyboardButton
                                    type="submit"
                                    shortcut={`${modifierKey} · Enter`}
                                    size="large"
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                    width="100%"
                                >
                                    Submit
                                </KeyboardButton>
                            </TalentFormBox>
                        </Form>
                    )
                }}
            </Formik>
        </Layout>
    )
}
