import styled from "@emotion/styled"
import { theme } from "@pallet-hq/hegel"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { NavButton } from "@pallet/features/onboardingL2/NavButton"
import { ProgressBar } from "@pallet/features/onboardingL2/ProgressBar"

export const NavBar = () => {
    const { handlePrevious, canHandlePrevious, activeIndex, totalSteps } =
        useNavigationControls()
    return (
        <Wrapper>
            <NavButton
                direction="previous"
                disabled={!canHandlePrevious}
                handleClick={handlePrevious}
            />
            <ProgressBar currentStep={activeIndex} totalSteps={totalSteps} />
            <div>{/* HACK: for proper space-between */}</div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.space.small} ${theme.space.medium};
    background: ${theme.colors.white};
`
