import { KeyboardButton, notify } from "@pallet-hq/hegel"
import { Layout } from "@pallet/features/onboardingL2/Layout"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { useUpdateUserMutation } from "@pallet/features/onboardingL2//useUpdateUserMutation"
import { useUserQuery } from "@pallet/features/onboardingL2/useUserQuery"
import { FormikProps } from "formik"
import { useRef } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import * as Yup from "yup"
import { CurrentJobTypeField } from "@pallet/components/fields/CurrentJobType"
import { CurrentExperienceField } from "@pallet/components/fields/CurrentExperience"
import { HasNoExperienceField } from "@pallet/components/fields/HasNoExperience"
import { CurrentTitleField } from "@pallet/components/fields/CurrentTitle"
import { CurrentCompanyField } from "@pallet/components/fields/CurrentCompany"
import { Formik } from "formik"
import { isMacintosh } from "utils/userAgent"
import {
    TalentWorkDetailsForm,
    TalentWorkDetailsFormValues,
} from "@pallet/features/onboardingL1/TalentWorkDetailsForm"
import CompanySelectImage from "components/inputs/Select/CompanySelectImage"

export const TalentWorkDetailsView = () => {
    const { handleNext } = useNavigationControls()
    const [updateUser] = useUpdateUserMutation()
    const userQuery = useUserQuery()
    const formRef = useRef<FormikProps<TalentWorkDetailsFormValues>>(null)
    const modifierKey = isMacintosh() ? "⌘" : "Ctrl"

    useHotkeys(
        "ctrl+enter, cmd+enter",
        () => {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
        },
        {
            enableOnTags: ["INPUT", "TEXTAREA"],
        }
    )

    if (!userQuery.data?.me?.id) return null

    return (
        <Layout>
            <Formik<TalentWorkDetailsFormValues>
                enableReinitialize
                initialValues={{
                    [CurrentJobTypeField.fieldName]: userQuery.data?.me
                        ?.currentJobType
                        ? {
                              value: userQuery.data.me.currentJobType.slug,
                              label: userQuery.data.me.currentJobType.longLabel,
                              abbreviatedLabel:
                                  userQuery.data.me.currentJobType.shortLabel,
                          }
                        : null,
                    [CurrentExperienceField.fieldName]:
                        userQuery.data?.me?.yearsOfExperience ?? null,
                    [HasNoExperienceField.fieldName]:
                        !!userQuery.data?.me?.hasNoWorkExperience,
                    [CurrentTitleField.fieldName]:
                        userQuery.data?.me?.currentTitle ?? "",
                    [CurrentCompanyField.fieldName]: userQuery.data?.me
                        ?.recentEmployer
                        ? {
                              value: userQuery.data.me.recentEmployer.slug,
                              label: userQuery.data.me.recentEmployer.name,
                              icon: (
                                  <CompanySelectImage
                                      node={{
                                          image: userQuery.data.me
                                              .recentEmployer.image,
                                      }}
                                  />
                              ),
                          }
                        : null,
                }}
                innerRef={formRef}
                validationSchema={Yup.object().shape({
                    ...CurrentJobTypeField.validators.required,
                    ...CurrentExperienceField.validators.required,
                    ...HasNoExperienceField.validators.required,
                    ...CurrentTitleField.validators.required,
                    ...CurrentCompanyField.validators.required,
                })}
                onSubmit={async values => {
                    const { data } = await updateUser({
                        variables: {
                            user: userQuery.data?.me?.slug ?? "",
                            currentJobType:
                                values[CurrentJobTypeField.fieldName]!.value,
                            yearsOfExperience:
                                values[CurrentExperienceField.fieldName],
                            hasNoWorkExperience:
                                values[HasNoExperienceField.fieldName],
                            currentTitle: !values[
                                HasNoExperienceField.fieldName
                            ]
                                ? values[CurrentTitleField.fieldName]
                                : undefined,
                            recentEmployer: !values[
                                HasNoExperienceField.fieldName
                            ]
                                ? values[CurrentCompanyField.fieldName]!.value
                                : undefined,
                        },
                    })
                    if (data?.updateJobSeeker?.userError) {
                        notify.fail("An error occurred. Please try again.")
                    } else {
                        handleNext()
                    }
                }}
                validateOnMount
            >
                {props => {
                    return (
                        <TalentWorkDetailsForm
                            formikProps={props}
                            submitButton={
                                <KeyboardButton
                                    type="submit"
                                    size="large"
                                    width="100%"
                                    loading={props.isSubmitting}
                                    disabled={!props.isValid}
                                    shortcut={`${modifierKey} · Enter`}
                                >
                                    Continue
                                </KeyboardButton>
                            }
                        />
                    )
                }}
            </Formik>
        </Layout>
    )
}
