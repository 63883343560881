import { useState } from "react"
import { Box, Button, Text, TextInputField } from "@pallet-hq/hegel"
import { EmailField } from "@pallet/components/fields/Email"
import { FirstNameField } from "@pallet/components/fields/FirstName"
import { LastNameField } from "@pallet/components/fields/LastName"
import OrDivider from "components/Modals/LoginModal/OrDivider"
import SocialLogins from "components/SocialLogins"
import { useApplyToCollectiveMutation } from "@pallet/features/onboardingL2/useApplyToCollectiveMutation"
import { useNavigationControls } from "@pallet/features/onboardingL2/useNavigationControls"
import { handleAfterLogin } from "@pallet/features/onboardingL2/utils"
import { Form, Formik } from "formik"
import useAuthContext from "state/useAuthContext"
import { handleLogin, handleSignup } from "utils/auth"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import * as Yup from "yup"
import { CandidateApplicationStepEnum } from "__generated__/globalTypes"
import { Layout } from "@pallet/features/onboardingL2/Layout"

export const TalentAccountView = () => {
    const [isLoggingIn, setLoggingIn] = useState(false)
    const LoginComponent = isLoggingIn ? LoginView : SignupView

    return (
        <Layout
            heading="With your Pallet account, "
            subheading="you can share your profile, apply for jobs, join new collectives, and so much more."
            hideNav={true}
            skipAuth={true}
        >
            <LoginComponent setLoggingIn={setLoggingIn} />
        </Layout>
    )
}

const SignupView = ({
    setLoggingIn,
}: {
    setLoggingIn: (value: boolean) => void
}) => {
    const { refresh } = useAuthContext()
    const [applyToCollective] = useApplyToCollectiveMutation()

    const startApplication = () => {
        applyToCollective({
            variables: {
                applicationStep: CandidateApplicationStepEnum.START,
            },
        })
    }

    return (
        <Formik
            initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
            }}
            validationSchema={Yup.object().shape({
                ...FirstNameField.validators.required,
                ...LastNameField.validators.required,
                ...EmailField.validators.required,
                password: Yup.string()
                    .required("Required")
                    .min(8, "Password must be over 8 characters long"),
                confirmPassword: Yup.string()
                    .required("Required")
                    .oneOf(
                        [Yup.ref("password")],
                        "Your passwords do not match."
                    ),
            })}
            onSubmit={async (values, helpers) => {
                const errors = await handleSignup(
                    values.email,
                    values.password,
                    false,
                    { firstName: values.firstName, lastName: values.lastName }
                )
                if (errors.length > 0) {
                    return helpers.setErrors({
                        email: "Email already exists. Please try again.",
                    })
                } else {
                    await refresh()
                    startApplication()
                }
            }}
            validateOnMount
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <Text variant="titleMedium" mb="large">
                            Create your account
                        </Text>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="small"
                        >
                            <Box
                                display="flex"
                                flexDirection={["column", "row"]}
                                gridGap="small"
                            >
                                <FirstNameField width="100%" />
                                <LastNameField width="100%" />
                            </Box>
                            <EmailField width="100%" />
                            <TextInputField
                                type="password"
                                label="Password"
                                name="password"
                                placeholder="Create a new password"
                                width="100%"
                            />
                            <TextInputField
                                type="password"
                                label="Confirm password"
                                name="confirmPassword"
                                placeholder="Confirm password"
                                width="100%"
                            />
                        </Box>
                        <Button
                            type="submit"
                            size="large"
                            width="100%"
                            mt="medium"
                            mb="small"
                            loading={isSubmitting}
                        >
                            Create account
                        </Button>
                        <Button
                            type="button"
                            variant="secondary"
                            size="large"
                            width="100%"
                            mb="32px"
                            disabled={isSubmitting}
                            onClick={() => setLoggingIn(true)}
                        >
                            Sign in with Pallet
                        </Button>
                        <OrDivider />
                        <SocialLogins />
                    </Form>
                )
            }}
        </Formik>
    )
}

const LoginView = ({
    setLoggingIn,
}: {
    setLoggingIn: (value: boolean) => void
}) => {
    const { refresh } = useAuthContext()
    const { pallet } = usePalletContext()
    const { setIsCheckingAuth } = useNavigationControls()
    const router = usePalletRouter()

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
            }}
            validationSchema={Yup.object().shape({
                ...EmailField.validators.required,
                password: Yup.string().required("Required"),
            })}
            onSubmit={async (values, helpers) => {
                setIsCheckingAuth(true)
                const errors = await handleLogin(
                    values.email,
                    values.password,
                    false
                )
                if (errors.length > 0) {
                    return helpers.setErrors({
                        password: "Login failed. Please try again.",
                    })
                } else {
                    await refresh()
                    await handleAfterLogin(router, pallet!)
                    setIsCheckingAuth(false)
                }
            }}
            validateOnMount
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <Text variant="titleMedium" mb="large">
                            Log in to your account
                        </Text>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="small"
                        >
                            <EmailField width="100%" />
                            <TextInputField
                                type="password"
                                label="Password"
                                name="password"
                                placeholder="Enter password"
                                width="100%"
                            />
                        </Box>
                        <Button
                            type="submit"
                            size="large"
                            width="100%"
                            loading={isSubmitting}
                            mt="medium"
                            mb="small"
                        >
                            Sign in with Pallet
                        </Button>
                        <Button
                            type="button"
                            variant="secondary"
                            size="large"
                            width="100%"
                            mb="32px"
                            disabled={isSubmitting}
                            onClick={() => setLoggingIn(false)}
                        >
                            Create account
                        </Button>
                        <OrDivider />
                        <SocialLogins />
                    </Form>
                )
            }}
        </Formik>
    )
}
